import './RelatedJobs.scss'

import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { FilterableListContext } from '../FilterableListContext'
import { ListTypeItem } from '../UI/ListTypeItem'
import { modifyRelatedJobItem } from '../utils/related-job'

export const RelatedJobs = () => {
  const { responseData, translationsState } = useContext(FilterableListContext)
  const { items, jobListingUrl } = responseData

  return (
    items?.length > 0 && (
      <div className="cmp-filterable-list cmp-filterable-list__related-jobs-wrapper">
        <div className="cmp-filterable-list__related-jobs-title">{translationsState.jobListingTitleText}</div>
        <div className={`cmp-filterable-list__cards cmp-filterable-list__related-jobs cmp-filterable-list--3-columns`}>
          {items?.map((item, index) => (
            <ListTypeItem
              item={modifyRelatedJobItem(item)}
              key={`${index}-${item.title}`}
              customTitle={
                <div className="cmp-filterable-list__card-title-wrapper">
                  <span className="cmp-button__icon icon-briefcase"></span>
                  <span className="cmp-filterable-list__card-title-wrapper--title">{item.title}</span>
                </div>
              }
              showMetaTimeToRead={false}
            />
          ))}
        </div>
        <ButtonsWrapper url={jobListingUrl} linkText={translationsState.jobListingButtonText} rel="noindex, follow" />
      </div>
    )
  )
}
