import { ButtonHTMLAttributes, useContext } from 'react'

import { FilterableListContext } from '../FilterableListContext'
import { ButtonListType } from '../FilterableListTypes'
import { ActionButton } from '../UI/ActionButton'
import { EditableLinks } from '../UI/EditableLinks'

export const ButtonsWrapper = ({
  linkText,
  url,
  onLoadMoreHandler,
  ...rest
}: {
  linkText?: string
  url?: string
  onLoadMoreHandler?: () => void
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { onLoadMore, propsState, responseData, translationsState } = useContext(FilterableListContext)
  const { loadMore } = responseData
  const showLoadMoreButton = onLoadMoreHandler ? true : propsState.loadMore === 'true' && loadMore

  const buttonsList: ButtonListType[] =
    propsState.linkType === 'editableLinks' ? JSON.parse(propsState.editableLinks) : propsState.links ? JSON.parse(propsState.links) : null
  const buttonAlignment = propsState.linkAlignment || propsState.buttonAlignment || 'center'

  if (buttonsList?.length > 0) {
    return <EditableLinks editableLinks={buttonsList} buttonAlignment={buttonAlignment} />
  }

  if (url) {
    return (
      <div className={`cmp-filterable-list__buttons-wrapper cmp-filterable-list__button--${buttonAlignment}`}>
        <ActionButton buttonType={'link'} icon={'keyboard_arrow_right'} url={url} {...rest} onClick={null}>
          {linkText}
        </ActionButton>
      </div>
    )
  }

  return (
    showLoadMoreButton && (
      <div className={`cmp-filterable-list__buttons-wrapper cmp-filterable-list__button--${buttonAlignment}`}>
        <ActionButton buttonType={'button'} icon={'keyboard_arrow_down'} onClick={onLoadMoreHandler || onLoadMore}>
          {translationsState.loadMore}
        </ActionButton>
      </div>
    )
  )
}
