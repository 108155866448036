import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { EventCarousel } from '../Components/Events/EventCarousel'
import { EventList } from '../Components/Events/EventList'
import { SimpleEvents } from '../Components/Events/SimpleEvents'
import { TeaserList } from '../Components/TeaserList'
import { FilterableListContext } from '../FilterableListContext'
import { ItemType } from '../FilterableListTypes'

export const Events = () => {
  const { responseData, propsState, translationsState } = useContext(FilterableListContext)
  const { items } = responseData
  const listStyleClassName = propsState.listStyle ? `cmp-filterable-list__events-type--${propsState.listStyle}` : ''
  const teaserColumnsClassName = propsState.teaserColumns ? `cmp-filterable-list--${propsState.teaserColumns}-columns` : ''

  const renderComponent = (items: ItemType[], listStyle = 'teaser') => {
    switch (listStyle) {
      case 'teaser':
        return <TeaserList buttonLabel={translationsState.watchNowLabel} items={items} />

      case 'list': {
        return <EventList items={items} />
      }

      case 'carousel':
        if (items.length >= 3) {
          return <EventCarousel items={items} eventProps={propsState} translations={translationsState} />
        } else {
          //return this p tag if want to show message <p>Carousel is hidden since the number of items is lower than 3.</p>
          return <p></p>
        }

      case 'simple':
        return (
          <SimpleEvents
            eventsPageLink={propsState.eventsPageLink}
            eventsPageLinkLabel={translationsState.seeAll}
            items={items}
            listTitle={propsState.hasHeader === 'true' && translationsState.listTitle}
          />
        )

      default:
        return <p>List style do not exist</p>
    }
  }
  return (
    <div className="cmp-filterable-list">
      <div
        className={cls({
          'cmp-filterable-list__list': propsState.listStyle === 'list',
          'cmp-filterable-list__cards': propsState.listStyle !== 'list',
          'cmp-filterable-list__list-events': true,
          'u-full-width': propsState.listStyle === 'carousel',
          [listStyleClassName]: propsState.listStyle,
          [teaserColumnsClassName]: propsState.teaserColumns,
        })}
      >
        {renderComponent(items, propsState.listStyle)}
      </div>
      {propsState.listStyle !== 'carousel' && <ButtonsWrapper url={propsState.eventsPageLink} linkText={translationsState.seeAll} />}
    </div>
  )
}
