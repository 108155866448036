import './Card.scss'

import { cls } from '@react/utils/classname'
import { ReactNode, useContext } from 'react'

import { FilterableListContext } from '../FilterableListContext'
import { ItemType } from '../FilterableListTypes'
import { TagItem } from './TagItem'

type CardProps = {
  additionalTemplate?: ReactNode
  buttonLabel?: string
  children?: ReactNode
  classes?: string
  customTitle?: ReactNode
  item: ItemType
  showButton?: boolean
  showDescription?: boolean
  showMetaDate?: boolean
  showMetaTag?: boolean
  showMetaTimeToRead?: boolean
  showTag?: boolean
  showTitle?: boolean
}

export const Card = ({
  additionalTemplate = null,
  buttonLabel,
  children,
  classes = '',
  customTitle = null,
  item,
  showButton = true,
  showDescription = true,
  showMetaDate = false,
  showMetaTag = false,
  showMetaTimeToRead = false,
  showTitle = true,
  showTag = true,
}: CardProps) => {
  const { propsState, translationsState } = useContext(FilterableListContext)
  const showMeta = showMetaTag || showMetaDate || showMetaTimeToRead

  const TitleComponent = () => {
    return customTitle || <h3 className="cmp-filterable-list__card-title">{item.title}</h3>
  }

  return (
    <div
      className={cls({
        'cmp-filterable-list__card': true,
        [classes]: true,
        'cmp-filterable-list__card--aspect-1-1': propsState.thumbnailAspectRatio === 'true',
      })}
    >
      <a className="cmp-filterable-list__card-link" href={item.url} data-dm={`${propsState.componentName}.link`}>
        {children}
        {showTag && <div className="cmp-filterable-list__card-tag">{item.tagValue || item.eventType}</div>}
        <div className="cmp-filterable-list__card-content">
          {item.tags?.length > 0 && (
            <div className="cmp-filterable-list__card-tags">
              {item.tags.map(tag => (
                <TagItem key={tag} tag={tag} />
              ))}
            </div>
          )}
          {showMeta && (
            <div className="cmp-filterable-list__card-meta">
              {showMetaTag && <span className="cmp-filterable-list__card-meta--tag">{item.tagValue}</span>}
              {showMetaDate && <span className="cmp-filterable-list__card-meta--date">{item.dateString}</span>}
              {showMetaTimeToRead && (
                <span className="cmp-filterable-list__card-meta--time-to-read">
                  {item.timeToRead} {translationsState.readingTime}
                </span>
              )}
            </div>
          )}
          {showTitle && <TitleComponent />}
          {showDescription && <p className="cmp-filterable-list__card-description">{item.description}</p>}
          {showButton && (
            <div className="cmp-filterable-list__card-read-more">
              <span className="cmp-filterable-list__card-read-more--text">{buttonLabel}</span>
              <span className="cmp-filterable-list__card-read-more--icon icon-keyboard_arrow_right icon--md"></span>
            </div>
          )}
        </div>
      </a>
      {additionalTemplate}
    </div>
  )
}
